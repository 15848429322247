@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&family=Open+Sans:wght@300;400;600;700&family=Oswald:wght@700&display=swap');

.material-symbols-outlined {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  letter-spacing: -0.01rem;
  & strong,
  & *.bold {
    font-family: 'Open Sans', sans-serif;
  }
}

.container {
  // max-width: 1600px !important;
}

.border {
  border: 2px solid #d9d9d9 !important;
}

//text styles

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', sans-serif;

  & strong,
  & *.bold {
    font-family: 'Open Sans', sans-serif;
  }
}

// text styles

.text-primary {
  color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
}

.text-secondary {
  color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1) !important;
}

// end of text styles

// background-styles
.bg-primary {
  background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
}

.bg-secondary {
  background-color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1) !important;
}

.bg-tertiary {
  background-color: hsla(var(--tertiary-h), var(--tertiary-s), var(--tertiary-l), 1) !important;
}

// end of background-styles

//? Button styling classes

.btn {
  & svg {
    vertical-align: middle;
    line-height: 0 !important;
    position: relative;
    top: -2px;
  }
  border-radius: 0rem;
  font-weight: 600;
  padding: 0.625rem 3rem;
}

.btn-primary {
  background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
  border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);

  &:hover {
    border-color: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 10%), 1);
    background-color: hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 10%), 1);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    border-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    pointer-events: none;
  }
}

.page-background {
  background-color: #ffffff;
  min-height: calc(100vh - 120px);
}

.btn-secondary {
  background-color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1);
  border-color: hsla(var(--secondary-h), var(--secondary-s), var(--secondary-l), 1);

  &:hover {
    border-color: hsla(var(--secondary-h), var(--secondary-s), calc(var(--secondary-l) + 10%), 1);
    background-color: hsla(var(--secondary-h), var(--secondary-s), calc(var(--secondary-l) + 10%), 1);
  }
}

.btn-success {
  background-color: hsla(var(--quaternary-h), var(--quaternary-s), var(--quaternary-l), 1);
  border-color: hsla(var(--quaternary-h), var(--quaternary-s), var(--quaternary-l), 1);

  &:hover {
    border-color: hsla(var(--quaternary-h), var(--quaternary-s), calc(var(--quaternary-l) + 10%), 1);
    background-color: hsla(var(--quaternary-h), var(--quaternary-s), calc(var(--quaternary-l) + 10%), 1);
  }
}

.btn-confirmed {
  color: #ffffff;
  background-color: hsla(var(--quinary-h), var(--quinary-s), var(--quinary-l), 1);
  border-color: hsla(var(--quinary-h), var(--quinary-s), var(--quinary-l), 1);

  &:hover {
    color: #ffffff;
    border-color: hsla(var(--quinary-h), var(--quinary-s), calc(var(--quinary-l) + 10%), 1);
    background-color: hsla(var(--quinary-h), var(--quinary-s), calc(var(--quinary-l) + 10%), 1);
  }
}

//? End of Button styling classes

.navbar {
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 100%;
}
.navbar-brand {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.875rem;
  letter-spacing: 0.25rem;
  color: #ffffff !important;
}
.navbar-nav .nav-link {
  font-size: 12px;
  letter-spacing: 0.25rem !important;
  font-weight: 600;
  color: #ffffff;
  padding: 0rem 2rem !important;
  &.active {
    color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
  }
  &:hover,
  &:focus {
    color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
  }
  & .btn {
    font-size: 12px;
  }
}
.footer .nav-link {
  font-size: 0.8rem;
  font-weight: 500;
  color: #ffffff;
  &.active {
    color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
  }
  &:hover,
  &:focus {
    color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
  }
}

#hero-text,
.section-title {
  font-family: 'Oswald', sans-serif !important;
  letter-spacing: 0.5rem !important;
}

.hero-wrapper {
  background-image: url(../../assets/images/bg-2.png);
  // filter: brightness(50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 900px;
  width: 100%;
  background-color: #000000 !important;
}

.pt-6 {
  padding-top: 6rem !important;
}
.pb-6 {
  padding-bottom: 6rem !important;
}
.py-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.pt-10 {
  padding-top: 10rem !important;
}
.pb-10 {
  padding-bottom: 10rem !important;
}
.py-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.technologies-sec .technologies-tabs .nav-link.active {
  color: #0348dd;
  border-bottom: 2px solid #0348dd;
}

.technologies-sec .technologies-tabs .tab-content .d-flex > div span {
  display: block;
  text-align: center;
  font-size: 17px;
  margin-top: 10px;
}
.technologies-sec .technologies-tabs .tab-content .d-flex > div .technology-thumb {
  height: 64px;
  width: 64px;
  background-image: url(../../assets/images/technologies-sprite.png);
  background-position: center;
  background-position-y: -18px;
  background-repeat: no-repeat;
  background-size: auto;
  overflow: hidden;
  display: inline-block;
  margin-top: 5px;
}
//? End of Nav Pills Styling classes

ul#pills-tab .owl-item:first-child::before {
  border-right: 0 solid #b3b4b6 !important;
}

ul#pills-tab .owl-item:before {
  content: '';
  border-right: 3px solid #b3b4b6;
  height: 100px;
  display: inline-block;
  position: absolute;
  bottom: 30%;
}

ul#pills-tab .owl-item {
  padding: 0px 20px;
}

button.owl-next span,
button.owl-prev span {
  font-size: 50px;
  color: #e21d3a;
  padding: 0 10px;
}

.owl-nav {
  position: absolute;
  top: 15%;
  width: 100%;
  height: 1px;
}
.owl-next {
  float: right;
}
.owl-prev {
  float: left;
}

.tech-tile:before {
  content: '';
  width: 150px;
  height: 150px;
  position: absolute;
  top: -15px;
  left: 35px;
  background-image: url(../../assets/images/polygon-1.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
}

.typed-cursor {
  font-size: 2rem;
}
// Fix for autofill styling
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #8b8b8b !important;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  background: #000;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
}

//

.mega-dropdown-menu {
  padding: 20px 0px;
  width: 450px;
  top: 45px;
  left: 0;

  @media screen and (max-width: '576px') {
    position: static !important;
  }
}

// .dropdown-menu-wrapper:before {
//   content: '';
//   border-bottom: 15px solid #fff;
//   border-right: 17px solid transparent;
//   border-left: 17px solid transparent;
//   position: absolute;
//   top: 4px;
//   left: 100px;
//   z-index: 10;
// }

.dropdown-menu-wrapper:after {
  content: '';
  border-bottom: 17px solid #ffffff;
  border-right: 19px solid transparent;
  border-left: 19px solid transparent;
  position: absolute;
  top: 4px;
  left: 100px;
  z-index: 8;
}
.dropdown-menu-wrapper a {
  text-decoration: none !important;
  color: #000000;
  font-size: 0.8rem;
}
.dropdown-menu-wrapper a .row.active,
.dropdown-menu-wrapper a .row.active:hover {
  background-color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
  color: #ffffff;
}

.dropdown-menu-wrapper a:hover .row {
  background-color: hsla(var(--tertiary-h), var(--tertiary-s), var(--tertiary-l), 1);
}

#nav-dropdown {
  .dropdown-toggle {
    &.active {
      color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1) !important;
    }
    color: #ffffff;
    &:hover {
      color: hsla(var(--primary-h), var(--primary-s), var(--primary-l), 1);
    }
  }
}

// End of Fix for autofill styling

//Media Queries

//End Of Media Queries
