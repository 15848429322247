footer {
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 0.9rem;
  z-index: 999;
  background-color: #000000;
  padding: 1.2rem;

  & svg {
    vertical-align: middle;
    line-height: 0 !important;
    position: relative;
    top: -1px;
  }
}
