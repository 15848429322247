/* Color Variables */
:root {
  --body-bg-h: 100;
  --body-bg-s: 100%;
  --body-bg-l: 100%;

  --body-text-h: 0;
  --body-text-s: 0%;
  --body-text-l: 0%;

  --navbar-bg-h: 217.5;
  --navbar-bg-s: 100%;
  --navbar-bg-l: 15.7%;

  --navbar-text-h: 0;
  --navbar-text-s: 0%;
  --navbar-text-l: 100%;

  --primary-h: 5.6;
  --primary-s: 78.1%;
  --primary-l: 57.1%;

  --secondary-h: 0;
  --secondary-s: 0%;
  --secondary-l: 0%;

  --tertiary-h: 0;
  --tertiary-s: 0%;
  --tertiary-l: 89.8%;

  /* Highlight Color */
  --quaternary-h: 194.8;
  --quaternary-s: 100%;
  --quaternary-l: 46.1%;

  /* Success Color */
  --quinary-h: 145.3;
  --quinary-s: 63.4%;
  --quinary-l: 41.8%;
}

body {
  background-color: hsla(var(--body-bg-h), var(--body-bg-s), var(--body-bg-l), 1) !important;
  color: hsla(var(--body-text-h), var(--body-text-s), var(--body-text-l), 1) !important;
}

.navbar {
  background-color: hsla(var(--navbar-bg-h), var(--navbar-bg-s), var(--navbar-bg-l), 1) !important;
  color: hsla(var(--navbar-text-h), var(--navbar-text-s), var(--navbar-text-l), 1) !important;
}
